import TablePage from '../../../../../../components/table_page';
import request from '../../../../../../utils/request';

export default {
  extends: TablePage,
  props: {
    rowItem: {},
  },
  data() {
    return {
      requestUrl: '/mdm/mdmcustomerterminal/correlatedCustomerPage',
      params: {
        customerCode: this.rowItem.customerCode,
      },
      isCalculateHeight: false,
      configs: {
        toolBarConfig: [
          // {
          //   name: '导出',
          //   buttonType: '0',
          //   buttonCode: 'head-import',
          // },
          {
            name: '解除上级客户',
            buttonType: '0',
            buttonCode: 'relieve',
          },
          {
            name: '替换上级客户',
            buttonType: '0',
            buttonCode: 'replace',
          },
        ],
      },
    };
  },
  methods: {
    // 重写 让其不被覆盖
    setButton() {},
    // 重新 覆盖 按钮事件
    buttonClick({ val }) {
      // if (val.buttonCode === 'head-import') {
      //   console.log(val);
      // } else
      if (val.buttonCode === 'relieve') {
        if (this.selectRow.length > 0) {
          const params = {
            customerCode: this.rowItem.customerCode,
            terminalCodeList: this.selectRow.map((v) => v.terminalCode),
          };
          request.post('/mdm/mdmcustomerterminal/unbind', params).then((res) => {
            if (res.success) {
              this.$emit('refresh');
            }
            this.$message({
              message: res.message,
              type: 'success',
            });
          });
        } else {
          this.$message.error('请选择数据');
        }
      } else if (val.buttonCode === 'replace') {
        if (this.selectRow.length > 0) {
          this.$emit('replace', this.selectRow);
        } else {
          this.$message.error('请选择数据');
        }
      }
    },
  },
  async created() {
    this.getConfigList('associat_terminal_list', true, true);
  },
  mounted() {},
};
