import request from '@/found/utils/request';
import TablePage from '../../../../../../components/table_page';
import Form from './form';

export default {
  extends: TablePage,
  props: {
    row: Object,
  },
  data() {
    return {
      params: {
        customerCode: this.row.customerCode,
      },
      configs: {
        toolBarConfig: [
          {
            name: '新增',
            code: 'head-add',
          },
          {
            name: '编辑',
            code: 'head-edit',
          },
          {
            name: '删除',
            code: 'head-delete',
          },
        ],
      },
      isCalculateHeight: false,
    };
  },
  components: {
    Form,
  },
  watch: {},
  methods: {
    // 重写 让其不被覆盖
    setButton() { },
    modalClick({ val }) {
      if (val.code === 'head-add') {
        this.modalConfig.title = '新增';
      } else if (val.code === 'head-edit') {
        if (this.selection.length === 0) {
          this.$message.error('请选择数据');
          return;
        } if (this.selection.length > 1) {
          this.$message.error('异常只能编辑一条数据');
          return;
        }
        this.modalConfig.title = '编辑';
      } else if (val.code === 'head-delete') {
        if (this.selection.length === 0) {
          this.$message.error('请选择数据');
          return;
        }
        const title = '此操作将永久删除此数据, 是否继续?';
        const url = '/mdm/mdmCustomerAddressController/delete';
        const msg = '删除';

        const data = {
          params: this.selection,
          url,
          title,
          msg,
        };
        this.messageBox(data);
        return;
      }

      this.formConfig = {
        type: val.code,
        customerCode: this.row.customerCode,
        id: val.code === 'head-edit' ? this.selection[0] : null,
      };

      this.openModal();
    },
    async getRoleButtonList() {
      const res = await request.get('/mdm/mdmCustomerMsgExtController/getAddressPermissionByPosition');
      if (res.success) {
        this.configs.toolBarConfig = res.result ? this.configs.toolBarConfig : [];
        // [{
        //   name: '新增',
        //   code: 'head-add',
        // }];
      }
    },
  },
  created() {
    this.getConfigList('address_manage', true, true);
    this.getRoleButtonList();
  },
};
