var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "associated_customer" },
    [
      _c(
        "div",
        { staticClass: "associated_customer_up" },
        [
          _c(
            "el-tabs",
            {
              attrs: { type: "card" },
              model: {
                value: _vm.activeTabPaneName,
                callback: function ($$v) {
                  _vm.activeTabPaneName = $$v
                },
                expression: "activeTabPaneName",
              },
            },
            [
              _c(
                "el-tab-pane",
                {
                  attrs: {
                    label: "未关联任何客户的终端",
                    name: "associatedCustomerOne",
                  },
                },
                [
                  _c("associatedCustomerOne", {
                    ref: "one",
                    attrs: { rowItem: _vm.formConfig },
                    on: { refresh: _vm.refresh },
                  }),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                {
                  attrs: {
                    label: "未关联当前客户的终端",
                    name: "associatedCustomerTwo",
                  },
                },
                [
                  _c("associatedCustomerTwo", {
                    ref: "two",
                    attrs: { rowItem: _vm.formConfig },
                    on: { refresh: _vm.refresh },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "associated_customer_line" }),
      _c(
        "div",
        { staticClass: "associated_customer_down" },
        [
          _c(
            "el-tabs",
            {
              attrs: { type: "card" },
              model: {
                value: _vm.active,
                callback: function ($$v) {
                  _vm.active = $$v
                },
                expression: "active",
              },
            },
            [
              _c(
                "el-tab-pane",
                {
                  attrs: {
                    label: "已关联当前客户的终端",
                    name: "RelationList",
                  },
                },
                [
                  _c("RelationList", {
                    ref: "three",
                    attrs: { rowItem: _vm.formConfig },
                    on: { refresh: _vm.refresh, replace: _vm.replaceFn },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "dialog-footer" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "danger", size: "small", icon: "el-icon-close" },
              on: { click: _vm.close },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
      _c("SelectConfig", {
        ref: "selectConfig",
        on: { onGetSelect: _vm.callBackFn },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }