<script>
import TablePage from '../../../../../../components/table_page';
import request from '../../../../../../utils/request';

export default {
  extends: TablePage,
  props: {
    rowItem: {
      type: Object,
    },
  },
  data() {
    return {
      requestUrl: '/mdm/mdmcustomerterminal/unCorrelatedAnyCustomerPage',
      params: {
        customerCode: this.rowItem.customerCode,
      },
      isCalculateHeight: false,
      configs: {
        toolBarConfig: [
          {
            name: '添加关联',
            buttonType: '0',
            buttonCode: 'relation',
          },
        ],
      },
    };
  },
  methods: {
    // 重写 让其不被覆盖
    setButton() {},
    // 重新 覆盖 按钮事件
    buttonClick({ val }) {
      if (val.buttonCode === 'relation') {
        if (this.selectRow.length > 0) {
          const params = {
            customerCode: this.rowItem.customerCode,
            terminalCodeList: this.selectRow.map((v) => v.terminalCode),
          };
          request.post('/mdm/mdmcustomerterminal/add', params).then((res) => {
            if (res.success) {
              this.$emit('refresh');
            }
            this.$message({
              message: res.message,
              type: 'success',
            });
          });
        } else {
          this.$message.error('请选择数据');
        }
      }
    },
  },
  created() {
    if (this.rowItem.enableStatus !== '009') {
      this.configs.toolBarConfig = [];
    }
    this.getConfigList('associat_terminal_list', true, true);
  },
  mounted() {},
};
</script>
