<template>
  <div class="associated_customer">
    <div class="associated_customer_up">
      <el-tabs v-model="activeTabPaneName" type="card">
        <el-tab-pane label="未关联任何客户的终端" name="associatedCustomerOne">
          <associatedCustomerOne
            :rowItem="formConfig"
            ref="one"
            @refresh="refresh"
          ></associatedCustomerOne>
        </el-tab-pane>
        <el-tab-pane label="未关联当前客户的终端" name="associatedCustomerTwo">
          <associatedCustomerTwo
            :rowItem="formConfig"
            ref="two"
            @refresh="refresh"
          ></associatedCustomerTwo>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="associated_customer_line"></div>
    <div class="associated_customer_down">
      <el-tabs v-model="active" type="card">
        <el-tab-pane label="已关联当前客户的终端" name="RelationList">
          <RelationList
            :rowItem="formConfig"
            ref="three"
            @refresh="refresh"
            @replace="replaceFn"
          ></RelationList>
        </el-tab-pane>
      </el-tabs>
    </div>

    <div class="dialog-footer">
      <el-button @click="close" type="danger" size="small" icon="el-icon-close">关闭</el-button>
    </div>
    <SelectConfig ref="selectConfig" @onGetSelect="callBackFn" />
  </div>
</template>

<script>
import Form from '../../../../../../components/form';
import associatedCustomerOne from './associated_customer_one.vue';
import associatedCustomerTwo from './associated_customer_two.vue';
import RelationList from './relation_list';
import SelectConfig from '../../../../../../components/select_org_cus_ter/select_config.vue';
import request from '../../../../../../utils/request';

export default {
  extends: Form,
  components: {
    associatedCustomerOne,
    associatedCustomerTwo,
    RelationList,
    SelectConfig,
  },
  data() {
    return {
      activeTabPaneName: 'associatedCustomerOne',
      active: 'RelationList',
      replaceList: [],
    };
  },
  methods: {
    // 关闭弹框
    close() {
      this.$emit('onClose');
    },
    // 联动刷新列表
    refresh() {
      this.$refs.one.getList();
      this.$refs.two.getList();
      this.$refs.three.getList();
    },
    replaceFn(list) {
      this.replaceList = list;
      const params = {
        functionCode: 'replace_customer_list',
        tableUrl: '/mdm/mdmCustomerMsgController/page',
        data: [],
        selectionList: [],
        idKey: 'customerCode',
      };
      this.$refs.selectConfig.openSelectModal(params);
    },
    callBackFn(list) {
      const params = {
        newCustomerCode: list[0].customerCode,
        oldCustomerCode: this.formConfig.customerCode,
        terminalCodeList: this.replaceList.map((v) => v.terminalCode),
      };
      request.post('/mdm/mdmcustomerterminal/replaceCustomer', params).then((res) => {
        this.selectionList = [];
        if (res.success) {
          this.refresh();
        }
        this.$message({
          message: res.message,
          type: 'success',
        });
      });
    },
  },
  mounted() {},
  created() {},
};
</script>

<style scoped lang="less">
.associated_customer_line {
  height: 2px;
  width: 100%;
  background-color: #aaa;
  margin: 30px 0;
}
/deep/.is-top{
  font-size: 13px;
}
.footer-btn {
  position: absolute;
  bottom: 0;
  right: 10px;

  button {
    width: 100px;
  }
}
</style>
