import Form from '../../../../../../components/form';
import request from '../../../../../../utils/request';

export default {
  extends: Form,
  data() {
    return {
      rule: [],
    };
  },
  async created() {
    const rule = await this.getBeforFormRule('address_manage_form');
    const that = this;

    rule.forEach((item) => {
      if (item.field === 'provinceCode') {
        Object.assign(item, {
          restful: '/mdm/mdmRegionController/select',
          optionsKey: { label: 'regionName', value: 'regionCode' },
          restfulParams: { regionLevel: '1' },
          props: {
            ...item.props,
            filterable: true,
            remote: true,
            remoteParams: 'regionName',
          },
          on: {
            change() {
              if (that.getFieldValue('cityCode')) {
                that.setValue({
                  cityCode: '',
                });
              }
              if (that.getFieldValue('districtCode')) {
                that.setValue({
                  districtCode: '',
                });
              }
              that.updateRules({
                cityCode: {
                  props: {
                    disabled: false,
                  },
                },
              });
            },
          },
        });
      } else if (item.field === 'cityCode') {
        Object.assign(item, {
          restful: '/mdm/mdmRegionController/select',
          optionsKey: { label: 'regionName', value: 'regionCode' },
          restfulParamsGetValue: { parentCode: 'provinceCode' },
          refresh: true,
          props: {
            ...item.props,
            filterable: true,
            remote: true,
            remoteParams: 'regionName',
            disabled: this.formConfig.type === 'head-add',
          },
          on: {
            change() {
              if (that.getFieldValue('districtCode')) {
                that.setValue({
                  districtCode: '',
                });
              }
              that.updateRules({
                districtCode: {
                  props: {
                    disabled: false,
                  },
                },
              });
            },
          },
        });
      } else if (item.field === 'districtCode') {
        Object.assign(item, {
          restful: '/mdm/mdmRegionController/select',
          optionsKey: { label: 'regionName', value: 'regionCode' },
          restfulParamsGetValue: { parentCode: 'cityCode' },
          refresh: true,
          props: {
            ...item.props,
            filterable: true,
            remote: true,
            remoteParams: 'regionName',
            disabled: this.formConfig.type === 'head-add',
          },
        });
      } else if (item.field === 'contactPhone') {
        Object
          .assign(item, {
            validate: [
              {
                trigger: 'blur',
                pattern: /^((0\d{2,3}-\d{7,8})|(1[3|4|5|6|7|8|9][0-9]\d{8}))$/,
                message: '请输入正确的电话号码',
              },
            ],
          });
      } else if (item.field === 'startTime' || item.field === 'endTime') {
        Object.assign(item, {
          props: {
            ...item.props,
            relation: {
              beginField: 'startTime',
              endField: 'endTime',
            },
          },
        });
      }
    });

    this.rule = rule;
    this.reload(this.rule);
    if (this.formConfig.type === 'head-edit') {
      request.get('/mdm/mdmCustomerAddressController/query', { id: this.formConfig.id }).then((res) => {
        if (res.success) {
          this.row = res.result;
          setTimeout(() => {
            this.setValue(res.result);
          }, 100);
        }
      });
    }
  },
  methods: {
    // 提交
    submit() {
      const formData = this.getFormData();
      if (formData) {
        let url = '/mdm/mdmCustomerAddressController/save';
        const params = { ...formData };
        params.customerCode = this.formConfig.customerCode;

        if (this.formConfig.type === 'head-edit') {
          url = '/mdm/mdmCustomerAddressController/update';
          params.id = this.row.id;
        }

        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });

            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
