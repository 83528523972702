import TablePage from '../../../../../components/table_page';
import Modal from '../../../../../components/modal';
import request from '../../../../../utils/request';
import {
  Form, AssociatTerminal, AddressManage, CustomerPortrait,
} from '../form';
import Submitapproval from '../components/submit_approve/index.vue';

export default {
  extends: TablePage,
  props: {
    treeCode: String,
  },
  data() {
    return {
      // requestUrl: '/mdm/mdmCustomerMsgController/page',
      params: {
        code: '',
      },
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: '',
      },
    };
  },
  components: {
    Modal,
    Form,
    AssociatTerminal,
    AddressManage,
    CustomerPortrait,
    Submitapproval,
  },
  watch: {
    treeCode(val) {
      this.params.code = val;
      this.getList();
    },
  },
  created() {
    this.getConfigList('customer_information_list');
  },
  methods: {
    beforeSearchEvent(val) {
      if (val.$event.type === 'reset') {
        this.$emit('reset');
      }
      return true;
    },
    beforeModalClick({ val }) {
      if (val.code === 'enable' || val.code === 'disable' || val.code === 'delete') {
        this.resetTree();
        return false;
      }
      return true;
    },
    // 控制按钮显示
    clickVisible({ row, val: { code } }) {
      // 审批通过 approved
      // 审批中 approving
      // 活动关闭 closed
      // 流程追回 interrupt
      // 驳回 rejected
      const { actApproveStatus, processCode } = row;
      if (actApproveStatus && code === 'submit_approval' && (actApproveStatus === '6' || actApproveStatus === '2' || actApproveStatus === '3')) {
        return false;
      }
      if (actApproveStatus && code === 'edit' && actApproveStatus === '2') { // 审批中不能编辑
        return false;
      }
      return true;
    },
    modalClick({ val, row }) {
      if (!this.beforeModalClick({ val, row })) return;
      this.formConfig = { ...val };
      let urls = '';
      let text = '';
      if (val.code === 'add') {
        this.formName = 'Form';
        this.formConfig = { ...val };
        this.modalConfig.title = '新增';
        this.openFull();
      } else if (val.code === 'edit') {
        this.formName = 'Form';
        this.formConfig = {
          ...val,
          row,
          customerCode: row.customerCode,
        };
        this.modalConfig.title = '编辑';
        this.openFull();
      } else if (val.code === 'view') {
        this.formName = 'Form';
        this.formConfig = {
          ...val,
          row,
          customerCode: row.customerCode,
          code: 'view',
        };
        this.modalConfig.title = '查看';
        this.openFull();
      } else if (val.code === 'update_list') {
        urls = '/mdm/mdmCustomerMsgController/update';
        text = '更新';
      } else if (val.code === 'associated_terminal') {
        this.formName = 'AssociatTerminal';
        this.formConfig = row;
        this.modalConfig.title = '关联终端';
        this.openFull();
      } else if (val.code === 'address') {
        this.formName = 'AddressManage';
        this.formConfig = row;
        this.modalConfig.title = '收货地址维护';
        console.log(row);
        this.openFull();
      } else if (val.code === 'available') {
        const selection = [];
        this.selectRow.forEach((v) => {
          selection.push(v.customerCode);
        });
        if (selection.length === 0) {
          this.$message('请选择一条数据');
          return false;
        }
        request.post(val.apiUrl, selection).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: `${val.buttonName}成功`,
            });
            this.getList();
          }
        });
      } else if (val.code === 'customer_portrait') {
        this.formName = 'CustomerPortrait';
        this.formConfig = row;
        this.modalConfig.title = '客户画像';
        this.openFull();
      } else if (val.code === 'submit_approval') {
        this.formName = 'Submitapproval';
        this.formConfig = { ...val, row };
        this.modalConfig.title = '提交审批';
        this.modalConfig.width = 1000;
        this.openFull();
      } else {
        console.log(val.code);
      }
    },
    operationClick(urls, text) {
      let datas = [];
      if (this.selection.length > 0) {
        datas = this.selection;
        request.post(urls, datas).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: `${text}成功`,
            });
            this.getList();
          }
        });
      } else {
        this.$message({
          type: 'error',
          message: '请选择数据',
        });
      }
    },
  },
};
