<template>
  <div class="content">
    <List :row='this.formConfig'/>
     <div class="dialog-footer">
       <el-button @click="close" type="danger" size="small" icon="el-icon-close">关闭</el-button>
    </div>
  </div>
</template>

<script>
import Form from '../../../../../../components/form';
import List from './pageConfig';

export default {
  name: 'addressManage',
  extends: Form,
  data() {
    return {};
  },
  components: {
    List,
  },
  methods: {
    // 关闭弹框
    close() {
      this.$emit('onClose');
    },
  },
};
</script>

<style lang="less" scoped>
.footer-btn {
  position: absolute;
  bottom: 0;
  right: 10px;

  button {
    width: 100px;
  }
}
</style>
